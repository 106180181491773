import React from "react"

/*Material-ui */
import { Grid, Card } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

/*Local files */
import Tracer from "../StatusTracker/index"
import {getCurrentPlan} from "../../utils/LocalStorage"

const useStyles = makeStyles(theme => ({
  ContenedorMain: {
    background:'linear-gradient(180deg, rgba(255,255,255,1) 10%, rgba(213,224,255,1) 76%)',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "950px",
    MozBackgroundSize: "cover",
    "@media (max-width: 720px)": {
      backgroundPosition: "40%",
    },
  },
  CardContent:{

      width:'80%',
      padding: '3%',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
  }
}))


const SectionOne = () => {
  const classes = useStyles()
  const InfoPlan = getCurrentPlan()
  const {NumeroEmpleados, Costo, Tipo} = InfoPlan
  return (
    <div>
        <Grid className={classes.ContenedorMain} xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            <Card className={classes.CardContent}>
                <Tracer 
                Nempleados={NumeroEmpleados}
                price={Costo}
                Tipo={Tipo}
                />
            </Card>
        </Grid>
    </div>
  )
}

export default SectionOne
